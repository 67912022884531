[data-component='list-scroller'] {
  // generic scroller - horizontal default
  &.scroller {
    display: flex;
    flex-flow: row;
  }
  // .scroller{
  display: flex;
  flex-flow: row;
  .incrementor-container {
    display: flex;
    align-items: center;
    .incrementor {
      flex: 1;
      opacity: 0;
      transition: opacity .3s;
      &:before {
        width: 100%;
        background: #fff;
        cursor: pointer;
        font-size: 5rem;
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
        color: @cta-primary;
      }
      &[data-wlsd=previous] {
        margin-right: 20px;
        &[data-overflowing=both] {
          opacity: 1;
        }
        &[data-overflowing=left],
        &[data-overflowing=top] {
          opacity: 1;
        }
        &:before {
          content: '\f053';
        }
      }
      &[data-wlsd=next] {
        margin-left: 20px;
        &[data-overflowing=both] {
          opacity: 1;
        }
        &[data-overflowing=right],
        &[data-overflowing=bottom] {
          opacity: 1;
        }
        &:before {
          content: '\f054';
        }
      }
    }
  }
  .scroll-container {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 1075px;
    white-space: nowrap;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    .scroll-container-contents {
      display: flex;
      float: left;
      transition: transform .3s ease-in-out;
      &.contents-no-transition {
        transition: none;
      }
      > li {
        display: inline-block;
        flex: 0 1 auto;
        margin: 0 8px;
      }
    }
  }

  &[data-scroller-orientation='vertical'] {
    .scroller {
      display: flex;
      flex-flow: row;
      flex-direction: column;
      margin-right: 3rem;
      .incrementor-container {
        min-height: 3rem;
        .incrementor {
          display: flex;
          justify-content: center;
          text-align: center;
          &:before {
            font-size: 2rem;
          }
          &[data-wlsd=previous] {
            margin: 0;
            &:before {
              content: '\f077';
            }
          }
          &[data-wlsd=next] {
            margin: 0;
            &:before {
              content: '\f078';
            }
          }
        }
      }
      .scroll-container {
        overflow-y: auto;
        margin: 1rem 0;
        .scroll-container-contents {
          display: flex;
          flex-direction: column;
          > li {
              margin-bottom: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
          }
        }
        li {
          flex: 0 1 5rem;
        }
      }
    }
  }
}

@media @mobile {

  .product-information .product-images {
    flex-direction: column-reverse;
  }

  [data-component='list-scroller'] {
    .incrementor-container{
      display: none;
    }
    &[data-scroller-orientation='vertical'] {
      .scroller {
        .incrementor-container{
                    margin: 0 2rem;
        }
        flex-direction: row;
        margin-right: 0;
        .scroll-container {
          li.thumbnail {
            max-width: 5rem;
            max-height: 8rem;
						
            label {
              img {
                width: auto;
                max-height: 4rem;
              }
            }
          }
          .scroll-container-contents {
            display: flex;
            flex-direction: row;
            > li {
              margin-bottom: 1rem;
              min-height: 4rem;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}