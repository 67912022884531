@import '../variables';

.tab-container {
  margin: 2rem 0;

  .tab-links {
    display: flex;
    justify-content: space-around;
    font-size: 2.5rem;
    font-weight: bold;
    padding: 1rem 0;
    border-bottom: 1px solid @tertiary;

    >a {
      position: relative;
      color: @primary;

      &.active {
        &:after {
          content: "";
          border-bottom: 5px solid @cta-primary;
          position: absolute;
          width: 100%;
          top: 3.7rem;
          left: 0; 
          right: 0;
        }
      }
    }
  }

  .tab-content {
    display: none;
    padding: 4rem 0;

    &.active {
      display: block;
    }
  }
}

@media @ipad-landscape {}

@media @ipad-portrait {
  .tab-container {
    .tab-links {
      font-size: 2rem;

      >a {
        &.active {
          &:after {
            top: 3.1rem;
          }
        }
      }
    }
  }
}

@media @mobile {
  .tab-container {
    .tab-links {
      font-size: 1.3rem;

      >a {
        &.active {
          &:after {
            top: 2.3rem;
          }
        }
      }
    }
  }
}