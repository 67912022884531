@import "../variables";

.product-type-icon {
  .icon {
    display: flex;
    justify-content: center;
    width: 47px;
    height: 47px;
    border-radius: 100%;
    border: 2px solid #88bc23;
    &.gm {
      background-color: @growing-media;
    }
    &.moles {
      background-color: @moles;
    }
    &.ferts-and-controls {
      background-color: @ferts-and-controls;
    }
    &.nematodes {
      background-color: @nematodes;
    }
  }
}

.product-tile {
  // background:lightblue;
  display: flex;
  flex-direction: column;
  min-width: 170px;
  max-width: 200px;
  min-height: 400px;
  justify-content: space-between;
  > a {
    margin-bottom: 15px;
  }
  .product-image {
    border: 1px solid @tertiary;
    padding: 15px;
    text-align: center;
    min-width: 200px;
    min-height: 203px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      max-height: 134px;
    }
  }
  .product-id {
    font-size: 1rem;
    text-transform: uppercase;
    margin: 0;
    display: flex;
    align-items: center;

    p {
      color: #807f7f;
      margin-right: 0.4rem;
      text-transform: uppercase;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .SKU {
      min-width: 30px;
    }

    a {
      font-size: 12px;
      color: @primary-lighter;
    }
    a:hover {
      color: #016833;
    }
  }
  h1 {
    // font-size:1.6rem;
    color: #000;
  }
  .product-details {
    flex: 1;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    .type {
      display: flex;
      min-width: 30px;
      .product-type-icon;
      margin-bottom: 5px;
      margin-top: 10px;

      .icon {
        display: flex;
        justify-content: center;
        width: 47px;
        height: 47px;
        border-radius: 100%;
        border: 2px solid #88bc23;
        &.gm {
          background-color: @growing-media;
        }
        &.moles {
          background-color: @moles;
        }
        &.ferts-and-controls {
          background-color: @ferts-and-controls;
        }
        &.nematodes {
          background-color: @nematodes;
        }
      }
    }
    .type .icon {
      width: 27px;
      height: 27px;
      border: none;
      &:nth-child(2) {
        margin-left: 10px;
      }
    }

    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      height: 5rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: @standard-font-size;
      color: #807f7f;
      white-space: normal;
    }

    h3 {
      max-width: 200px;
      height: 4rem;
      overflow: hidden;
      color: @default-font-colour;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      margin-top: 10px;
      a {
        color: @primary-lighter;
        &:hover {
          color: @primary;
        }
      }
    }
  }
  .product-pricing {
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    margin-top: 0.5rem;

    h1 {
      margin-right: 5px;
      color: @primary;
    }

    p {
      font-size: 14px;
    }
  }
  .product-actions {
    display: flex;
    form {
      flex: 1;
      display: flex;
      flex-flow: column;
      .custom-checkbox {
        margin: 10px 0;
      }
      div {
        display: flex;
      }
      label {
        flex: 3;
        margin-right: 5px;
        display: flex;
        align-items: center;
        input[type="checkbox"] {
          margin-right: 10px;
        }
      }
      button {
        flex: 1;
      }
    }
  }

  .add-to-compare {
    margin: 10px 0;
    font-size: 1.2rem;
    input {
      margin-right: 5px;
      // width:20px;
      // height:20px;
    }
  }
}

@media @mobile {
  .product-tile {
    .product-image {
      min-width: 180px;
      min-height: 203px;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fallback-rules;
}

.fallback-rules {
  .product-information {
    display: -ms-grid;
    -ms-grid-column: 2fr 1fr;
    width: auto;
    .product-details {
      margin-left: 91rem;
    }
  }
  .step-input {
    height: 4rem;
  }
  .product-tile {
    min-height: 450px;
    .product-image img {
      margin-top: 20px;
    }
    .product-details {
      min-height: 18rem;

      h3 {
        min-height: 4rem;
      }
      .description {
        min-height: 5rem;
        margin-top: 10px;
      }
    }
    .product-actions form {
      .custom-checkbox {
        margin-bottom: 20px;
        span {
          margin-top: 15px;
        }
      }
    }
  }
}