@import "../variables";
@import "../components/scroller";
@import "./product-tile";
@import "./tabs";

@tertiary-background: #ebebeb;

.breadcrumb {
  display: none;
}

main {
  // margin
  .categories {
    background-color: @tertiary-background;
    padding: 8rem 2rem;

    .inner-wrapper ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2rem;
      line-height: 0;

      li {
        &:nth-child(3) {
          grid-row: span 2;

          a .category img {
            max-height: 100%;
          }
        }

        // &:nth-child(6) {
        //   grid-column: span 2;
        //   a {
        //     .category{
        //       img {
        //         max-width: 81rem;
        //       }
        //     }
        //   }
        // }

        a {
          .category {
            position: relative;
            height: 100%;

            picture {
              display: block;
            }

            img {
              // max-height: 30rem;
              height: 100%;
              width: 100%;
              max-height: 31rem;
              max-width: 40rem;
            }

            header {
              position: absolute;
              top: 0;
              width: 100%;
              text-align: center;
              background-image: url(/images/Product-Title-tab.png);
              background-repeat: no-repeat;
              background-position-x: 50%;
              padding: 2rem 0;

              h1 {
                color: #fff;
                font-size: 2rem;
                line-height: 0;
              }
            }
            .loading-container img {
              max-width: inherit;
              max-height: inherit;
              height: 51px;
              width: 67px;
            }
          }
        }
      }
    }
  }

  .brands {
    background: @tertiary-background;
    position: relative;
    > img {
      width: 40%;
      position: absolute;
      bottom: 0;
      &:first-of-type {
        left: 0;
      }
      &:last-of-type {
        right: 0;
      }
    }
    &:before {
      content: "";
      width: 100%;
      height: auto;
      position: absolute;
      border-bottom: 9px solid #37a636;
      bottom: 0;
      //left: 50%;
      //transform: translateX(-50%)
    }
    .inner-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
      padding-bottom: 6.4rem;

      .image-container {
        text-align: center;
        margin-top: 20px;
      }
    }
  }

  .home-images {
    padding: 4rem 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    a {
      position: relative;
      span {
        position: absolute;
        bottom: 25%;
        font-size: 2.4rem;
        padding: 0 38% 0 10%;
        width: 100%;
        color: #fff;
        z-index: 1;
      }
      &:last-of-type {
        span {
          color: #fff200;
        }
      }
      .product-image {
        img {
          width: 100%;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}

//fallback
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fallback-rules;
}

@supports not (display: grid) {
  .fallback-rules;
}

.fallback-rules {
  main {
    .product-tile .product-pricing {
      margin-top: 5rem;
    }

    main .categories .inner-wrapper ul li:nth-child(3) a .category img {
      max-width: 100%;
    }
    .categories {
      .inner-wrapper ul {
        display: flex;
        flex-wrap: wrap;

        li {
          flex: 1;
          flex-basis: 33.3333%;
          max-width: 33.333%;
          margin: 5px 0;
          &:nth-child(3) {
            grid-row: span 2;

            a .category img {
              max-height: 31rem;
            }
          }
          a {
            color: transparent;
          }
        }
      }
    }

    .brands .inner-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media @ipad-landscape {
  main {
    .categories {
      .inner-wrapper {
        ul {
          li {
            // &:nth-child(6) {
            //   a .category header {
            //     background-size: 50%;
            //   }
            // }

            a .category header {
              padding: 1.2rem 0;
              h1 {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }

    a .category header {
      background-size: 100%;
      height: 100%;
      padding-top: 3px;
      background-size: 100%;
      height: 100%;

      h1 {
        padding: 0;
      }
    }
    .brands {
      > img {
        width: 50%;
      }
      &:before {
        border-bottom: 5px solid #37a636;
      }
    }
  }

  .home-images {
    display: flex;
    .product-image {
      margin: 18px;
    }
  }
}

@media @ipad-portrait {
  main {
    .categories {
      .inner-wrapper {
        ul {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 20px;
          li {
            &:nth-child(3) {
              grid-row: span 1;
            }
            a .category {
              img {
                max-height: 100%;
              }
              header {
                // padding: 1.4rem 0;
                // header {
                padding: 1rem 0;
                background-image: url(/images/Title-carrier.png);
                // h1 {
                // font-size: 1rem;
                // }
                // }
                h1 {
                  font-size: 1.8rem;
                  line-height: 2.5;
                }
              }
            }
          }
        }
      }
    }

    .brands {
      .inner-wrapper {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    .home-images {
      padding: 1rem 0;
      display: flex;
      flex-direction: column;

      .product-image {
        margin: 7px 14px;
      }
    }
    // .carousel {
    //   // ul li .slide-content-layer .slide-content .button {
    //   //   width: 150px;
    //   // }
    //   .carousel-item-markers ol .item-marker {
    //     font-size: 1.5rem;
    //   }
    // }
  }
}

@media @mobile {
  main {
    .categories {
      padding: 4rem 0;
      .inner-wrapper {
        grid-template-columns: repeat(2, 1fr);
        ul {
          li {
            // &:nth-child(6) {
            //   grid-column: 1;
            //   a .category header {
            //     background-size: 100%;
            //   }
            // }
            a .category {
              margin-top: 0;

              header {
                // background-image: url(/images/Title-carrier.png);
                height: 100%;
                padding: 1rem 0;
                h1 {
                  font-size: 12px;
                  line-height: 1.5;
                }
              }
            }
          }
        }
      }
    }

    .brands {
      // >img {
      //   width: 70%;
      //   &:first-of-type{
      //     padding-right: 6rem
      //   }
      //   &:last-of-type{
      //     padding-left: 6rem;
      //   }
      // }
      &:before {
        border-bottom: 2px solid #37a636;
      }
      .inner-wrapper {
        grid-template-columns: 1fr;
      }
    }

    .home-images {
      padding: 1rem 0;
      display: flex;
      flex-direction: column;

      .product-image {
        margin: 7px 14px;
      }
    }
  }
}

@media @mobile-small {
  main {
    .categories {
      .inner-wrapper {
        grid-template-columns: repeat(2, 1fr);
        ul {
          li {
            a .category {
              header {
                padding: 1rem 0;
                background-image: url(/images/Title-carrier.png);
                h1 {
                  font-size: 1rem;
                  line-height: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fallback-rules;
}

@supports not (display: grid) {
  .fallback-rules;
}